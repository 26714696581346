import React, { CSSProperties, FC, memo, MouseEventHandler, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import i18n from "@/localization/i18next";

import Button from "./Button";

interface StickyButtonProps {
    children?: ReactNode;
    isDisabled?: boolean;
    className?: string;
    btnClassName?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    style?: CSSProperties;
    disabledStyles?: string;
    id?: string;
    testId?: string;
}

const StickyButton: FC<StickyButtonProps> = ({
    onClick,
    isDisabled,
    className,
    btnClassName,
    style,
    children = i18n.t("continue_button"),
}) => {
    return (
        <div
            className={twMerge(
                `sticky bottom-0 z-20 grid w-full justify-items-center bg-zing-bg-sticky-39 pb-6 female:bg-zing-bg-sticky-39-white md:bg-zing-bg-sticky-30 md:pb-10 md:female:bg-zing-bg-sticky-30-white`,
                className
            )}
            style={style}
        >
            <Button
                className={twMerge(btnClassName, "sm:max-w-[327px]")}
                onClick={onClick}
                isDisabled={isDisabled}
            >
                {children}
            </Button>
        </div>
    );
};

export default memo(StickyButton);
