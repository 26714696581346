export const enum ButtonTestIds {
    CHECKBOX_OPTION = "checkbox-option",
    RADIO_OPTION = "radio-option",
    EMAIL_SUBSCRIBE_AGREE = "email-subscribe-sure-button",
    UPGRADE_SUB_DONT_TAKE_DISCOUNT = "upgrade-sub-refuse-discount-button",
    CONTINUE = "continue-button",
}

export const enum ContainerTestIds {
    PLAN_ITEM = "plan-item-container",
}

export const enum LabelTestIds {
    PAYMENT_PLAN_NAME = "payment-plan-name-label",
    PAYMENT_PLAN_OLD_PRICE = "payment-plan-old-price-label",
    PAYMENT_PLAN_TARGET_PRICE = "payment-plan-target-price-label",
    PRICE_WHOLE_PART = "price-whole-label",
    PRICE_FRACTIONAL_PART = "price-fractional-label",
    PRICE_OLD_VALUE = "price-old-value-label",
}

export const getButtonTestIdByText = (text: string) => {
    return `${text.toLocaleLowerCase().split(" ").join("-")}-button`;
};

export const getRadioTestIdByText = (text: string) => {
    return `${text.toLocaleLowerCase().split(" ").join("-")}-option`;
};

export const getCheckboxTestIdByText = (text: string) => {
    return `${text.toLocaleLowerCase().split(" ").join("-")}-option`;
};
