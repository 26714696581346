import {
    ChangeEvent,
    KeyboardEventHandler,
    memo,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import classNames from "classnames";

import mailSvg from "@/assets/icons/mail.svg";
import { useDeviceType } from "@/hooks/useDeviceType";
import { useOnClickOutside } from "@/hooks/useOnclickOutside";
import i18n from "@/localization/i18next";

const EMAIL_ID = "email";
const EMAIL_LIST_ID = "email_options";
const EMAIL_SUGGESTIONS = ["gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "icloud.com"];

const CLOSING_DELAY_TIMEOUT = 500;

interface EmailProps {
    email: string;
    isLoading?: boolean;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    setEmail: (email: string) => void;
    isEmailFocused: boolean;
    setIsEmailFocused: (focused: boolean) => void;
    isEmailSuggestion?: boolean;
}

export const EmailInput = memo<EmailProps>(
    ({
        email,
        isLoading,
        onKeyDown,
        setEmail,
        isEmailFocused,
        setIsEmailFocused,
        isEmailSuggestion,
    }) => {
        const containerRef = useRef<HTMLDivElement>(null);
        const emailRef = useRef<HTMLInputElement>(null);
        const [isDataListVisible, setDataListVisible] = useState(false);
        const [selectedDomain, setSelectedDomain] = useState<string>("");
        const { isMobile, isAndroid } = useDeviceType();

        const suggestions = useMemo(() => {
            const domainPart = email.split("@")[1];
            return EMAIL_SUGGESTIONS.filter(
                (domain) => domain.includes(domainPart) && domain !== domainPart
            ).sort((a, b) => a.indexOf(domainPart) - b.indexOf(domainPart));
        }, [email]);

        const inputEmail = (e: ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
            setSelectedDomain("");
        };

        useOnClickOutside(containerRef, () => setIsEmailFocused(false));

        const handleKeyUp = (e: KeyboardEvent) => {
            const value = (e.target as HTMLInputElement).value;

            if (value?.includes("@")) {
                setDataListVisible(true);
            } else {
                setDataListVisible(false);
            }
        };

        useEffect(() => {
            if (emailRef.current) {
                emailRef.current.addEventListener("keyup", handleKeyUp);
            }
        }, []);

        useEffect(() => {
            const isAutofocus = !isMobile || isAndroid;
            if (emailRef.current && isAutofocus) {
                isAutofocus && emailRef.current.focus();
            }
        }, [isMobile, isAndroid]);

        const handleFocusEmail = () => {
            if (!isEmailFocused) {
                setIsEmailFocused(true);
            }
        };

        const handleClickSuggestion = (suggestion: string) => () => {
            setSelectedDomain(suggestion);

            setTimeout(() => {
                setEmail(suggestion);
                setDataListVisible(false);
            }, CLOSING_DELAY_TIMEOUT);
        };

        return (
            <div className="relative" ref={containerRef}>
                <input
                    ref={emailRef}
                    id={EMAIL_ID}
                    list={EMAIL_LIST_ID}
                    value={email}
                    type="email"
                    title="email"
                    autoComplete="off"
                    onChange={inputEmail}
                    onKeyDown={onKeyDown}
                    className={classNames(
                        `text-base z-10 w-full border border-zing-gray-400 bg-black px-2 py-3.5 pl-12 text-white placeholder-zing-gray-400 outline-none focus:border-zing-blue-200 female:border-zing-neutral-cool-grey-400 female:bg-transparent female:text-black female:placeholder-zing-neutral-cool-grey-400 female:focus:border-zing-warm-tangerine-400`,
                        {
                            "rounded-2xl": !isDataListVisible || !suggestions.length,
                            "rounded-t-2xl": isDataListVisible && suggestions.length,
                        }
                    )}
                    placeholder={i18n.t("your_email")}
                    onFocus={handleFocusEmail}
                    disabled={isLoading}
                />
                {isEmailSuggestion && (
                    <div className="absolute top-0 -z-10 px-2 py-3.5 pl-12 text-transparent">
                        <span>{email.split("@")[0]}@</span>
                        <span className="border-spacing-4 border-b-2 border-b-zing-orange-200 pb-1">
                            {email.split("@")[1]}
                        </span>
                    </div>
                )}
                <div
                    className={classNames(
                        "absolute z-30 w-full rounded-b-3xl bg-zing-neutral-cool-grey-800 px-12 text-zing-neutral-cool-grey-200 transition-[opacity] delay-100 duration-500 female:bg-zing-neutral-cool-grey-200 female:text-zing-neutral-cool-grey-700",
                        {
                            "-top-[1000%] py-0 opacity-0":
                                !isDataListVisible || !suggestions.length,
                            "h-auto py-4": isDataListVisible && suggestions.length,
                        }
                    )}
                >
                    <ul className="grid gap-4">
                        {suggestions.map((domain) => {
                            const value = email.split("@")[0] + "@" + domain;
                            return (
                                <li
                                    key={value}
                                    className={classNames("cursor-pointer", {
                                        "text-zing-warm-neon-green-500 female:text-zing-warm-tangerine-500":
                                            value === selectedDomain,
                                    })}
                                    onClick={handleClickSuggestion(value)}
                                >
                                    {value}
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <img src={mailSvg} className="absolute left-4 top-1/2 -translate-y-1/2" alt="" />
            </div>
        );
    }
);
