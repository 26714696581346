import femTenTo from "@/female/assets/body-fat/10-14.webp";
import femFifteenTo from "@/female/assets/body-fat/15-19.webp";
import femTwentyTo from "@/female/assets/body-fat/20-24.webp";
import femTwentyFiveTo from "@/female/assets/body-fat/25-29.webp";
import femThirtyTo from "@/female/assets/body-fat/30-34.webp";
import femThirtyFiveTo from "@/female/assets/body-fat/35-39.webp";
import femFortyTo from "@/female/assets/body-fat/40-44.webp";
import femFortyFiveTo from "@/female/assets/body-fat/45-49.webp";
import femTenToPaywall from "@/female/assets/paywall-body-fat/10-14.webp";
import femFifteenToPaywall from "@/female/assets/paywall-body-fat/15-19.webp";
import femTwentyToPaywall from "@/female/assets/paywall-body-fat/20-24.webp";
import femTwentyFiveToPaywall from "@/female/assets/paywall-body-fat/25-29.webp";
import femThirtyToPaywall from "@/female/assets/paywall-body-fat/30-34.webp";
import femThirtyFiveToPaywall from "@/female/assets/paywall-body-fat/35-39.webp";
import femFortyToPaywall from "@/female/assets/paywall-body-fat/40-44.webp";
import femFortyFiveToPaywall from "@/female/assets/paywall-body-fat/45-49.webp";
import fiveTo from "@/male/assets/body-fat/5-9.webp";
import tenTo from "@/male/assets/body-fat/10-14.webp";
import fifteenTo from "@/male/assets/body-fat/15-19.webp";
import twentyTo from "@/male/assets/body-fat/20-24.webp";
import twentyFiveTo from "@/male/assets/body-fat/25-29.webp";
import thirtyTo from "@/male/assets/body-fat/30-34.webp";
import thirtyFiveTo from "@/male/assets/body-fat/35-39.webp";
import fortyTo from "@/male/assets/body-fat/40-44.webp";
import fiveToPaywall from "@/male/assets/paywall-body-fat/5-9.webp";
import tenToPaywall from "@/male/assets/paywall-body-fat/10-14.webp";
import fifteenToPaywall from "@/male/assets/paywall-body-fat/15-19.webp";
import twentyToPaywall from "@/male/assets/paywall-body-fat/20-24.webp";
import twentyFiveToPaywall from "@/male/assets/paywall-body-fat/25-29.webp";
import thirtyToPaywall from "@/male/assets/paywall-body-fat/30-34.webp";
import thirtyFiveToPaywall from "@/male/assets/paywall-body-fat/35-39.webp";
import fortyToPaywall from "@/male/assets/paywall-body-fat/40-44.webp";

import {
    logAnalyticsEvent,
    logPaltaAnalyticsEvent,
    logSingleSourceAnalyticsEvent,
    setAnalyticsUserProperties,
    setPaltaAnalyticsUserProperties,
    setSingleSourceAnalyticsUserProperties,
} from "./amplitude";
import { WebEventTypes } from "./event";
import { sendMetrics } from "./gtag";
import { QUESTIONS_IDS } from "./questionsIds";

export const enum BodyMetricTypes {
    WEIGHT = "weight",
    HEIGHT = "height",
    AGE = "age",
}

export enum UnitTypes {
    Imperial = "imperial",
    Metric = "metric",
}

export enum WeightDimensions {
    POUNDS = "pounds",
    KG = "kilograms",
}

export enum HeightDimensions {
    FT = "ft/in",
    CM = "cm",
}

export const inputInfoByUnitType = {
    [UnitTypes.Imperial]: {
        [BodyMetricTypes.WEIGHT]: {
            dimension: WeightDimensions.POUNDS,
            placeHolder: (isAgeFlow?: boolean) => (isAgeFlow ? 159 : 150),
        },
        [BodyMetricTypes.HEIGHT]: {
            dimension: HeightDimensions.FT,
            placeHolder: (isAgeFlow?: boolean) => (isAgeFlow ? 5.8 : 5.7),
        },
        [BodyMetricTypes.AGE]: null,
    },
    [UnitTypes.Metric]: {
        [BodyMetricTypes.WEIGHT]: {
            dimension: WeightDimensions.KG,
            placeHolder: (isAgeFlow?: boolean) => (isAgeFlow ? 79 : 70),
        },
        [BodyMetricTypes.HEIGHT]: {
            dimension: HeightDimensions.CM,
            placeHolder: (isAgeFlow?: boolean) => (isAgeFlow ? 159 : 170),
        },
        [BodyMetricTypes.AGE]: null,
    },
};

export enum WeightRangesTypes {
    "0-34" = "0-34",
    "35-44" = "35-44",
    "45-54" = "45-54",
    "55-64" = "55-64",
    "65-74" = "65-74",
    "75-84" = "75-84",
    "85-94" = "85-94",
    "95-104" = "95-104",
    "105-114" = "105-114",
    "115-124" = "115-124",
    "125-134" = "125-134",
    "135-144" = "135-144",
    "145-154" = "145-154",
    "155+" = "155+",
}

const MIN_RANGE_WEIGHT = 34;

export const calculateWeightRange = (weight: number): WeightRangesTypes | null => {
    const index = Math.ceil((weight - MIN_RANGE_WEIGHT) / 10);

    return (
        (Object.keys(WeightRangesTypes)[index] as WeightRangesTypes) || WeightRangesTypes["155+"]
    );
};

export enum HeightRangesTypes {
    "0-129" = "0-129",
    "130-144" = "130-144",
    "145-154" = "145-154",
    "155-164" = "155-164",
    "165-174" = "165-174",
    "175-184" = "175-184",
    "185-194" = "185-194",
    "195-204" = "195-204",
    "205-214" = "205-214",
    "215+" = "215+",
}

export const calculateHeightRange = (height: number): HeightRangesTypes | null => {
    if (height >= 215) return HeightRangesTypes["215+"];
    let resultRange = null;

    Object.keys(HeightRangesTypes).forEach((el) => {
        const range = el.split("-");
        if (height >= +range[0] && height <= +range[1]) {
            resultRange = el;
        }
    });

    return resultRange;
};

export enum AgeRangesTypes {
    "0-18" = "0-18",
    "18-25" = "18-25",
    "26-35" = "26-35",
    "36-45" = "36-45",
    "45+" = "45+",
}

function calculateAgeRange(age: number): AgeRangesTypes | null {
    const ranges: [number, number][] = [
        [0, 17],
        [18, 25],
        [26, 35],
        [36, 45],
        [45, Infinity],
    ];

    const rangeIndex = ranges.findIndex(([_, max]) => age <= max);

    return rangeIndex !== -1 ? (Object.keys(AgeRangesTypes)[rangeIndex] as AgeRangesTypes) : null;
}

export const questionIDToMapper = {
    [QUESTIONS_IDS.AGE]: calculateAgeRange,
    [QUESTIONS_IDS.HEIGHT]: calculateHeightRange,
    [QUESTIONS_IDS.CURRENT_WEIGHT]: calculateWeightRange,
};

export enum MaleBodyFatRangeTypes {
    "5-9" = "5-9",
    "10-14" = "10-14",
    "15-19" = "15-19",
    "20-24" = "20-24",
    "25-29" = "25-29",
    "30-34" = "30-34",
    "35-39" = "35-39",
    ">40" = ">40",
}

export const defaultMaleBodyFatRangeValues = {
    skinny: MaleBodyFatRangeTypes["15-19"],
    regular: MaleBodyFatRangeTypes["20-24"],
    extra: MaleBodyFatRangeTypes["25-29"],
};

export enum FemaleBodyFatRangeTypes {
    "10-14" = "10-14",
    "15-19" = "15-19",
    "20-24" = "20-24",
    "25-29" = "25-29",
    "30-34" = "30-34",
    "35-39" = "35-39",
    "40-44" = "40-44",
    ">45" = ">45",
}

export const paywallBodyFatValues: Record<MaleBodyFatRangeTypes | FemaleBodyFatRangeTypes, string> =
    {
        "5-9": "5-9",
        "10-14": "10-14",
        "15-19": "10-14",
        "20-24": "10-14",
        "25-29": "15-19",
        "30-34": "15-19",
        "35-39": "15-19",
        ">40": "25-29",
        "40-44": "25-29",
        ">45": "25-29",
    };
export const returnBodyFat = (
    bodyFatParam: MaleBodyFatRangeTypes | FemaleBodyFatRangeTypes
): string => paywallBodyFatValues[bodyFatParam];
export const ImagesByBodyFatRange: Record<MaleBodyFatRangeTypes, string> = {
    [MaleBodyFatRangeTypes["5-9"]]: fiveTo,
    [MaleBodyFatRangeTypes["10-14"]]: tenTo,
    [MaleBodyFatRangeTypes["15-19"]]: fifteenTo,
    [MaleBodyFatRangeTypes["20-24"]]: twentyTo,
    [MaleBodyFatRangeTypes["25-29"]]: twentyFiveTo,
    [MaleBodyFatRangeTypes["30-34"]]: thirtyTo,
    [MaleBodyFatRangeTypes["35-39"]]: thirtyFiveTo,
    [MaleBodyFatRangeTypes[">40"]]: fortyTo,
};

export const FemaleImagesByBodyFatRange: Record<FemaleBodyFatRangeTypes, string> = {
    [FemaleBodyFatRangeTypes["10-14"]]: femTenTo,
    [FemaleBodyFatRangeTypes["15-19"]]: femFifteenTo,
    [FemaleBodyFatRangeTypes["20-24"]]: femTwentyTo,
    [FemaleBodyFatRangeTypes["25-29"]]: femTwentyFiveTo,
    [FemaleBodyFatRangeTypes["30-34"]]: femThirtyTo,
    [FemaleBodyFatRangeTypes["35-39"]]: femThirtyFiveTo,
    [FemaleBodyFatRangeTypes["40-44"]]: femFortyTo,
    [FemaleBodyFatRangeTypes[">45"]]: femFortyFiveTo,
};

export const PaywallImagesByBodyFatRange: Record<MaleBodyFatRangeTypes, string> = {
    [MaleBodyFatRangeTypes["5-9"]]: fiveToPaywall,
    [MaleBodyFatRangeTypes["10-14"]]: tenToPaywall,
    [MaleBodyFatRangeTypes["15-19"]]: fifteenToPaywall,
    [MaleBodyFatRangeTypes["20-24"]]: twentyToPaywall,
    [MaleBodyFatRangeTypes["25-29"]]: twentyFiveToPaywall,
    [MaleBodyFatRangeTypes["30-34"]]: thirtyToPaywall,
    [MaleBodyFatRangeTypes["35-39"]]: thirtyFiveToPaywall,
    [MaleBodyFatRangeTypes[">40"]]: fortyToPaywall,
};

export const FemPaywallImagesByBodyFatRange: Record<FemaleBodyFatRangeTypes, string> = {
    [FemaleBodyFatRangeTypes["10-14"]]: femTenToPaywall,
    [FemaleBodyFatRangeTypes["15-19"]]: femFifteenToPaywall,
    [FemaleBodyFatRangeTypes["20-24"]]: femTwentyToPaywall,
    [FemaleBodyFatRangeTypes["25-29"]]: femTwentyFiveToPaywall,
    [FemaleBodyFatRangeTypes["30-34"]]: femThirtyToPaywall,
    [FemaleBodyFatRangeTypes["35-39"]]: femThirtyFiveToPaywall,
    [FemaleBodyFatRangeTypes["40-44"]]: femFortyToPaywall,
    [FemaleBodyFatRangeTypes[">45"]]: femFortyFiveToPaywall,
};

export const sendBodyMetricsAnalytics = (
    id: QUESTIONS_IDS,
    event: string,
    value: number | null
) => {
    const mapper = questionIDToMapper[id as keyof typeof questionIDToMapper];

    if (mapper && value) {
        logPaltaAnalyticsEvent(WebEventTypes.RESULT_EVENT, {
            screen: event,
            result: value,
        });
        setPaltaAnalyticsUserProperties({ [id]: value });
        const rangeValue = mapper(value);
        logSingleSourceAnalyticsEvent(WebEventTypes.RESULT_EVENT, {
            screen: event,
            result: rangeValue,
        });
        setSingleSourceAnalyticsUserProperties({ [id]: rangeValue });
        sendMetrics(WebEventTypes.RESULT_EVENT, { screen: event, result: rangeValue });
    } else {
        logAnalyticsEvent(WebEventTypes.RESULT_EVENT, {
            screen: event,
            result: value,
        });
        setAnalyticsUserProperties({ [id]: value });
        sendMetrics(WebEventTypes.RESULT_EVENT, { screen: event, result: value });
    }
};
