import React, { Dispatch } from "react";

import { LazyComponentWithPreload, lazyWithPreload } from "@/utils/lazyPreload";
import { localStorageManager, LocalStorageManagerKeys } from "@/utils/localStorageManager";
import { FlowTypes } from "@/utils/providers/types";
import { step } from "@/utils/settings";

const Main = lazyWithPreload(() => import("@/components/Main"));
const NameScreen = lazyWithPreload(() => import("@/components/NameScreen/index"));
const EmailScreen = lazyWithPreload(() => import("@/components/EmailScreen"));
const EmailSubscribeScreen = lazyWithPreload(() => import("@/components/EmailSubscribeScreen"));
const Loader = lazyWithPreload(() => import("@/components/Promo/LoaderPromo"));
const AgeGroupScreen = lazyWithPreload(() => import("@/components/AgeGroupScreen"));
const AgeAdaptPromoScreen = lazyWithPreload(
    () => import("@/age-adaptation/components/AgeAdaptPromoScreen")
);
const Deeplink = lazyWithPreload(() => import("@/components/Deeplink Screen/Deeplink"));
const BodyScan = lazyWithPreload(() =>
    import("@/components/BodyScan/BodyScan").then(({ BodyScan }) => ({ default: BodyScan }))
);
const Upsell = lazyWithPreload(() => import("@/components/Upsells/Workbook"));
const ProgressLoader = lazyWithPreload(() => import("@/components/ProgressLoader/ProgressLoader"));
const CircularLoader = lazyWithPreload(() => import("@/components/Loader/CircularLoader"));
const DateChart = lazyWithPreload(() => import("@/components/Promo/DateChart"));
const ActionPlan = lazyWithPreload(() => import("@/components/ActionPlan"));
const AuthForm = lazyWithPreload(() => import("@/components/AuthForm"));
const UpgradeSub = lazyWithPreload(() => import("@/components/UpgradeSub"));
const ChartImage = lazyWithPreload(() => import("@/components/Prepaywall/ChartImage"));
const PromoPaywall = lazyWithPreload(() => import("@/components/Paywall/PromoPaywall"));
const AIPaywall = lazyWithPreload(() => import("@/components/Paywall/AIPaywall"));
const AIWeightPredict = lazyWithPreload(
    () => import("@/components/AIWeightPredict/WeightPredictScreen")
);
const BodyScanPrepaywall = lazyWithPreload(() => import("@/components/BodyScanPrepaywall"));
const AlreadyWithUs = lazyWithPreload(() => import("@/components/AlreadyWithUs"));

Main.preload();

export const enum COMPONENTS {
    QUESTIONS = "Questions",
    PROGRESS_LOADER = "ProgressLoader",
    FIRST_LOADER = "FirstLoader",
    LOADER = "Loader",
    EMAIL = "Email",
    EMAIL_SUBSCRIBE = "EmailSubscribe",
    CHART = "Chart",
    PAYWALL = "Paywall",
    DEEPLINK = "Deeplink",
    WORKBOOK = "Workbook",
    BODY_SCAN = "BodyScan",
    DATE_CHART = "DateChart",
    AI_PAYWALL = "AiPaywall",
    AUTH_FORM = "AuthForm",
    UPGRADE_SUB = "UpgradeSub",
    ACTION_PLAN = "ACTION_PLAN",
    PLANS_FIT_COMPLETE = "PlansFitComplete",
    FORM_HABIT = "FormHabit",
    JOIN_HEALTHIER_YEAR = "JoinHealthierYear",
    FIRST_STEP_HABIT = "FirstStepHabit",
    HABIT_CHART = "HabitChart",
    RESOLUTION_PREDICT = "ResolutionPredict",
    RESOLUTION_COMMIT = "ResolutionCommit",
    FIRST_SCREEN_AGE = "FirstScreenAge",
    AGE_ADAPT_PROMO_SCREEN = "AgeAdaptPromoScreen",
    SOCIAL_PROOF = "SocialProof",
    AI_WEIGHT_PREDICT = "AiWeightPredict",
    BODY_SCAN_PREPAYWALL = "BodyScanPrepaywall",
    ALREADY_WITH_US = "AlreadyWithUs",
    NAME = "NameScreen",
}

export interface CommonFlowComponentProps {
    setComponent: Dispatch<React.SetStateAction<COMPONENTS>>;
}
export const components: { [key in COMPONENTS]?: LazyComponentWithPreload } = {
    [COMPONENTS.FIRST_LOADER]: Loader,
    [COMPONENTS.FIRST_SCREEN_AGE]: AgeGroupScreen,
    [COMPONENTS.QUESTIONS]: Main,
    [COMPONENTS.EMAIL]: EmailScreen,
    [COMPONENTS.EMAIL_SUBSCRIBE]: EmailSubscribeScreen,
    [COMPONENTS.AGE_ADAPT_PROMO_SCREEN]: AgeAdaptPromoScreen,
    [COMPONENTS.DEEPLINK]: Deeplink,
    [COMPONENTS.BODY_SCAN]: BodyScan,
    [COMPONENTS.WORKBOOK]: Upsell,
    [COMPONENTS.PROGRESS_LOADER]: ProgressLoader,
    [COMPONENTS.LOADER]: CircularLoader,
    [COMPONENTS.DATE_CHART]: DateChart,
    [COMPONENTS.ACTION_PLAN]: ActionPlan,
    [COMPONENTS.AUTH_FORM]: AuthForm,
    [COMPONENTS.UPGRADE_SUB]: UpgradeSub,
    [COMPONENTS.CHART]: ChartImage,
    [COMPONENTS.PAYWALL]: PromoPaywall,
    [COMPONENTS.AI_PAYWALL]: AIPaywall,
    [COMPONENTS.AI_WEIGHT_PREDICT]: AIWeightPredict,
    [COMPONENTS.BODY_SCAN_PREPAYWALL]: BodyScanPrepaywall,
    [COMPONENTS.ALREADY_WITH_US]: AlreadyWithUs,
    [COMPONENTS.NAME]: NameScreen,
};

export const getInitComponent = ({ flowType }: { flowType: FlowTypes | null }) => {
    if (flowType === FlowTypes.Email) {
        return COMPONENTS.PAYWALL;
    }
    if (localStorageManager.getStoredItem<boolean>(LocalStorageManagerKeys.IS_AUTH_PASSED)) {
        return COMPONENTS.DEEPLINK;
    }
    if (localStorageManager.getStoredItem<boolean>(LocalStorageManagerKeys.IS_AUTH_STEP)) {
        return COMPONENTS.AUTH_FORM;
    }
    if (localStorageManager.getStoredItem<boolean>(LocalStorageManagerKeys.IS_PAYWALL_STEP)) {
        return flowType === FlowTypes.Friends ? COMPONENTS.PAYWALL : COMPONENTS.AI_PAYWALL;
    }
    if (step) {
        return (step.charAt(0).toUpperCase() + step.slice(1)) as COMPONENTS;
    }
    if (flowType === FlowTypes.Both || flowType === FlowTypes.Friends) {
        return COMPONENTS.QUESTIONS;
    }
    if (flowType === FlowTypes.AgeAdaptation) {
        return COMPONENTS.FIRST_SCREEN_AGE;
    }
    return COMPONENTS.QUESTIONS;
};
